<template>
  <header class="flex flex-col pb-12 bg-white">
    <section class="flex flex-col flex-wrap justify-center content-center pb-12 w-full bg-teal-50 max-md:max-w-full">
      <div class="flex z-10 flex-col justify-center items-start py-5 pr-16 pl-5 w-full bg-white shadow-sm max-md:pr-5 max-md:max-w-full">
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/0ffff473201a235bbb77282d1a4df3b5da141cba5a97505993c7dafeec511b8e?apiKey=1c8c6b3b5ca94f9eaeace649c77c8484&" class="max-w-full aspect-[4.17] w-[103px]" />
      </div>
      <div class="self-center mt-0 max-w-full w-[1156px]">
        <div class="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
          <div class="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div class="flex flex-col self-stretch px-5 my-auto font-bold max-md:mt-10 max-md:max-w-full">
              <h1 class="text-4xl text-slate-600 max-md:max-w-full">
                <span class="text-5xl text-slate-600">Documenti Elmec SPA</span><br />
                <span class="text-xl text-slate-600">Consultazione documenti</span>
              </h1>
              <button class="download-doc" @click="downloadPDF">
                <div class="flex flex-col flex-wrap justify-center content-center px-3.5 mt-5 text-xs text-center whitespace-nowrap bg-white rounded-2xl shadow-sm h-[122px] text-stone-500 w-[122px]">
                  <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/b67acd2714b0d92a41d12cd833026ab2c660b4f3d561d8617f377d912e59e149?apiKey=1c8c6b3b5ca94f9eaeace649c77c8484&" class="self-center aspect-[1.02] w-[52px]" />
                  <div class="mt-2.5">Policy<br />Whistleblowing</div>
                </div>
              </button>
            </div>
          </div>
          <div class="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <img loading="lazy" srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c1712063f386f5517d593380255d81f22e41e11ccddd21df8cf8146a70f5e720?apiKey=1c8c6b3b5ca94f9eaeace649c77c8484&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c1712063f386f5517d593380255d81f22e41e11ccddd21df8cf8146a70f5e720?apiKey=1c8c6b3b5ca94f9eaeace649c77c8484&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c1712063f386f5517d593380255d81f22e41e11ccddd21df8cf8146a70f5e720?apiKey=1c8c6b3b5ca94f9eaeace649c77c8484&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c1712063f386f5517d593380255d81f22e41e11ccddd21df8cf8146a70f5e720?apiKey=1c8c6b3b5ca94f9eaeace649c77c8484&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c1712063f386f5517d593380255d81f22e41e11ccddd21df8cf8146a70f5e720?apiKey=1c8c6b3b5ca94f9eaeace649c77c8484&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c1712063f386f5517d593380255d81f22e41e11ccddd21df8cf8146a70f5e720?apiKey=1c8c6b3b5ca94f9eaeace649c77c8484&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c1712063f386f5517d593380255d81f22e41e11ccddd21df8cf8146a70f5e720?apiKey=1c8c6b3b5ca94f9eaeace649c77c8484&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c1712063f386f5517d593380255d81f22e41e11ccddd21df8cf8146a70f5e720?apiKey=1c8c6b3b5ca94f9eaeace649c77c8484&" class="self-stretch w-full aspect-square max-w-[600px] max-md:mt-8 max-md:max-w-full" />
          </div>
        </div>
      </div>
    </section>
  </header>
</template>

<script>
export default {
  methods: {
    downloadPDF() {
      window.open("ElmecSPA_WHISTLEBLOWING.pdf", "_blank")
    }
  }
};
</script>